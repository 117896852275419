import React, { useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addUserSchema } from "../../../schema/index";

import "../../../scss/main.css";

const addEditStyle = {
    addEditTitle: {
        ...typographyStyles.h4,
    },
    addEditLabel: {
        ...typographyStyles.h6,
    },
    radioContainer: {
        display: 'flex',
    },
};

const dropdownOptions = ["Artist", "Listener"];

const EditUser = () => {
    const [thumbnail, setThumbnail] = useState(null);

    const handleThumbnailChange = (event) => {
        const file = event.target.files[0];
        setThumbnail(file);
        formik.setFieldValue('userImg', file);
    };

    const Navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: "",
            phone: "",
            username: "",
            userType: "",
            userImg: null,
            status: "active",
        },
        validationSchema: addUserSchema, // Use the imported schema
        onSubmit: () => {
            alert("Added");
            Navigate('/userlisting');
        },
    });

    return (
        <DashboardLayout sectionName="User Management" title="Edit User">
            <Box className="add-edit-container">
                <form className="add-edit-content-section" onSubmit={formik.handleSubmit}>
                    <Box className="add-edit-content">
                        <Box className="add-edit-input-container">
                            <Box className="add-edit-input-label">
                                <Typography sx={addEditStyle.addEditLabel}>Email Address:</Typography>
                            </Box>
                            <Box className="add-edit-input-field">
                                <InputField
                                    type="text"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Enter email address"
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <p style={{ color: "red", float: "left" }}>
                                        {formik.errors.email}
                                    </p>
                                ) : null}
                            </Box>
                        </Box>
                        <Box className="add-edit-input-container">
                            <Box className="add-edit-input-label">
                                <Typography sx={addEditStyle.addEditLabel}>Phone Number:</Typography>
                            </Box>
                            <Box className="add-edit-input-field">
                                <InputField
                                    type="text"
                                    name="phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Enter phone number"
                                />
                                {formik.touched.phone && formik.errors.phone ? (
                                    <p style={{ color: "red", float: "left" }}>
                                        {formik.errors.phone}
                                    </p>
                                ) : null}
                            </Box>
                        </Box>
                        <Box className="add-edit-input-container">
                            <Box className="add-edit-input-label">
                                <Typography sx={addEditStyle.addEditLabel}>Username:</Typography>
                            </Box>
                            <Box className="add-edit-input-field">
                                <InputField
                                    type="text"
                                    name="username"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Enter username"
                                />
                                {formik.touched.username && formik.errors.username ? (
                                    <p style={{ color: "red", float: "left" }}>
                                        {formik.errors.username}
                                    </p>
                                ) : null}
                            </Box>
                        </Box>
                        <Box className="add-edit-input-container">
                            <Box className="add-edit-input-label">
                                <Typography sx={addEditStyle.addEditLabel}>User Type:</Typography>
                            </Box>
                            <Box className="add-edit-input-field">
                                <Dropdown
                                    name="userType"
                                    options={dropdownOptions}
                                    value={formik.values.userType}
                                    onChange={(value) => formik.setFieldValue('userType', value)}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.userType && formik.errors.userType ? (
                                    <p style={{ color: "red", float: "left" }}>
                                        {formik.errors.userType}
                                    </p>
                                ) : null}
                            </Box>
                        </Box>
                        <Box className="add-edit-input-container">
                            <Box className="add-edit-input-label">
                                <Typography sx={addEditStyle.addEditLabel}>Status:</Typography>
                            </Box>
                            <Box className="add-edit-input-field">
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                                    >
                                        <FormControlLabel value="active" control={<Radio />} label="Active" />
                                        <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                                    </RadioGroup>
                                </FormControl>
                                {formik.touched.status && formik.errors.status ? (
                                    <p style={{ color: "red", float: "left" }}>
                                        {formik.errors.status}
                                    </p>
                                ) : null}
                            </Box>
                        </Box>
                        <Box className="add-edit-input-container">
                            <Box className="add-edit-input-label">
                                <Typography sx={addEditStyle.addEditLabel}>User Thumbnail Image:</Typography>
                            </Box>
                            <Box className="add-edit-input-field">
                                <InputField
                                    type="file"
                                    name="userImg"
                                    onChange={handleThumbnailChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{ accept: 'image/*' }}
                                />
                                {formik.touched.userImg && formik.errors.userImg ? (
                                    <p style={{ color: "red", float: "left" }}>
                                        {formik.errors.userImg}
                                    </p>
                                ) : null}
                            </Box>
                        </Box>
                    </Box>
                    <Box className="add-edit-button-section">
                        <BasicButtons text="Add" name='submit' type='submit' />
                        <BasicButtons text="Back" onClick={() => Navigate("/userlisting")} />
                    </Box>
                </form>
            </Box>
        </DashboardLayout>
    );
};

export default EditUser;
