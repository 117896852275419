// export const baseurl = "https://node-earnsystem.mobiloitte.io"; //stagging
// export const baseurl = "http://182.72.203.249:2061"; //ip
//export const baseurl = "http://localhost:2070"; //local Kamal
export const baseurl = "https://node-arappdev.mobiloitte.io";

const url = `${baseurl}/api`;

const ApiConfig = {
  //ADMIN AUTH
  login: `${url}/user/userLogin`,
  forgotPassword: `${url}/admin/resendOtp`,
  verifyOTP: `${url}/admin/verifyOTP`,
  resetPassword: `${url}/admin/resetPassword`,
  // changePassword: `${url}/admin/changePassword`,
  // getProfile: `${url}/admin/getProfile`,
  // editProfile: `${url}/admin/editProfile`,
  // resendOtp: `${url}/admin/resendOtp`,

  //ADMIN MANAGEMENT
  getAllUserFeedback: `${url}/admin/getAllUserFeedback`,
  getAllUserByType: `${url}/admin/getAllUserByType`,
  getUserBySpotifyId: `${url}/admin/getUserBySpotifyId`,
  getFeedbackBySpotifyId : `${url}/admin/getFeedbackBySpotifyId`,
  updateProfile:`${url}/admin/updateProfile`,
  getUser:`${url}/user/getUser`,
  changePassword:`${url}/admin/changePassword`,
  replyFeedback: `${url}/admin/replyFeedback`,

  // //USER MANAGEMENT
  // addUser: `${url}/admin/userList`,
  // userList: `${url}/admin/userList`,
  // viewUser: `${url}/admin/viewUser`,
  // deleteUser: `${url}/admin/deleteUser`,

  //STATIC MANAGEMENT
  getAllStaticContentData: `${url}/static/getAllStaticContentData`,
  getStaticContentByType: `${url}/static/getStaticContentByType`,
  updateStaticContent: `${url}/static/updateStaticContent`,
  addStaticContent: `${url}/static/addStaticContent`,

  // //FAQ MANAGEMENT
  // faqList: `${url}/static/faqList`,
  // deleteFAQ: `${url}/static/deleteFAQ`,
  // editFAQ: `${url}/static/editFAQ`,
  // addFAQ: `${url}/static/addFAQ`,
  // viewFAQ: `${url}/static/viewFAQ`,

  // //TICKET MANAGEMENT
  // getTickets: `${url}/ticket/getTickets`,
  // createTicket: `${url}/ticket/createTicket`,
  // updateTicket: `${url}/ticket/updateTicket`,
  // blockTicket: `${url}/ticket/blockTicket`,
  // deleteTicket: `${url}/ticket/deleteTicket`,
  // viewTicket: `${url}/ticket/viewTicket`,

  // //NOTIFICATION MANAGEMENT
  // listNotification: `${url}/notification/listNotification`,
  // viewNotification: `${url}/notification/viewNotification`,
  // deleteNotification: `${url}/notification/deleteNotification`,

  // //DASHBOARD MANAGEMENT
  // dashBoard: `${url}/admin/dashBoard`,

  // // graph analytics
  // graphForUser: `${url}/admin/graphForUser`,
  // graphGameHistory: `${url}/admin/graphGameHistory`,
  // userRegistration: `${url}/admin/userRegistration`,
  // userGameList: `${url}/game/userGameList`,


};
export default ApiConfig;
