import React, { useState, useEffect } from "react";
import { Box, Typography, Link, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import urlImg from "../../../assets/profile_pic.jpeg";
import { FaExternalLinkAlt } from "react-icons/fa";
import Player from "../../../components/Player";
import { getAPIHandler } from "../../../ApiConfig/service";
// import dotenv from "dotenv";
// dotenv.config();

const ViewUser = () => {
  const { id } = useParams();
  const [artist, setArtist] = useState(null);
  const [user, setUser] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [topTracks, setTopTracks] = useState([]);
  const [fairPrice, setFairPrice] = useState(null);
  const [revenueEstimates, setRevenueEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null);

  const addEditStyle = {
    addEditLabel: {
      ...typographyStyles.h5,
      color: "black",
    },
    addEditInfo: {
      ...typographyStyles.h6,
      fontWeight: "300",
    },
    albumTrackItem: {
      position: "relative",
      overflow: "hidden",
      cursor: "pointer",
      transition: "transform 0.2s, filter 0.2s",
      "&:hover": {
        transform: "scale(1.1)",
        filter: "brightness(0.8)",
      },
    },
    albumTrackImage: {
      width: "100%",
      height: "auto",
      display: "block",
    },
  };

  const formatFollowers = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  };

  const fetchArtistUUID = async (id) => {
    const url = `https://customer.api.soundcharts.com/api/v2.9/artist/by-platform/spotify/${id}`;
    const headers = {
      "x-app-id": "soundcharts",
      "x-api-key": "soundcharts",
    };

    try {
      const response = await axios.get(url, { headers });

      if (response.data && response.data.object && response.data.object.uuid) {
        const uuid = response.data.object.uuid;
        console.log("Artist UUID:", uuid);
        return uuid;
      } else {
        console.error("Error: UUID not found in response data");
        throw new Error("UUID not found in response data");
      }
    } catch (error) {
      console.error("Error fetching artist UUID:", error);
      throw error;
    }
  };

  const fetchTotalListeners = async (uuid) => {
    const platform = "spotify";
    const url = `https://customer.api.soundcharts.com/api/v2/artist/${uuid}/streaming/${platform}/listening`;
    const headers = {
      "x-app-id": "soundcharts",
      "x-api-key": "soundcharts",
    };

    try {
      console.log("Fetching total listeners for UUID:", uuid);
      const response = await axios.get(url, { headers });
      console.log("API Response:", response.data);

      if (response.data.items && response.data.items.length > 0) {
        console.log("Items Array:", response.data.items);

        let maxValue = -Infinity;
        response.data.items.forEach((item) => {
          if (item.value !== undefined && typeof item.value === "number") {
            if (item.value > maxValue) {
              maxValue = item.value;
            }
          } else {
            console.warn(
              "Encountered invalid or undefined 'value' in an item:",
              item
            );
          }
        });

        if (maxValue !== -Infinity) {
          console.log("Max Total Listeners:", maxValue);
          return maxValue;
        } else {
          console.error("Error: No valid 'value' found in items array");
          throw new Error("No valid 'value' found in items array");
        }
      } else {
        console.error(
          "Error: Items array is empty or undefined in the response"
        );
        throw new Error("Items array is empty or undefined in the response");
      }
    } catch (error) {
      console.error("Error fetching total listeners:", error);
      throw error;
    }
  };

  const calculateFairPrice = (totalListeners) => {
    const discountRate = 0.1;
    const startYear = 2024;
    const endYear = 2030;
    const duration = endYear - startYear + 1;

    const estimatedCashFlows = [];

    for (let i = 0; i < duration; i++) {
      const estimatedCashFlow = totalListeners * (i + 1);
      estimatedCashFlows.push(estimatedCashFlow);
    }

    let fairPrice = 0;
    for (let i = 0; i < duration; i++) {
      fairPrice += estimatedCashFlows[i] / Math.pow(1 + discountRate, i + 1);
    }

    return {
      fairPrice: fairPrice.toFixed(2),
      revenueEstimates: estimatedCashFlows.map((cashFlow, index) => ({
        year: startYear + index,
        revenue: cashFlow.toFixed(2),
      })),
    };
  };

  const fetchArtistData = async (id, token) => {
    try {
      const artistResponse = await axios.get(
        `https://api.spotify.com/v1/artists/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setArtist(artistResponse.data);

      const albumsResponse = await axios.get(
        `https://api.spotify.com/v1/artists/${id}/albums`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setAlbums(albumsResponse.data.items);

      const topTracksResponse = await axios.get(
        `https://api.spotify.com/v1/artists/${id}/top-tracks`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setTopTracks(topTracksResponse.data.tracks);

      // const uuid = await fetchArtistUUID(id);

      // if (uuid) {

      //   const totalListeners = await fetchTotalListeners(uuid);

      //   const { fairPrice, revenueEstimates } =
      //     calculateFairPrice(totalListeners);
      //   setFairPrice(fairPrice);
      //   setRevenueEstimates(revenueEstimates);
      // }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching artist data:", error);
      setError(error);
      setLoading(false);
    }
  };

  const fetchUserData = async (spotifyId) => {
    try {
      const response = await getAPIHandler({
        endPoint: "getUserBySpotifyId",
        paramsData: { spotifyId },
      });
      setUser(response.data.result);
      console.log("Response of the user", response.data.result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getToken();

        if (id.startsWith("spotify:artist:")) {
          const artistId = id.split(":")[2];
          await fetchArtistData(artistId, token);
        } else if (id.startsWith("spotify:user:")) {
          const userId = id.split(":")[2];
          await fetchUserData(userId);
        } else {
          setError(new Error("Invalid Spotify ID format"));
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const getToken = async () => {
    const clientId = "fc4807a6796a4f0a807da37d69dd8826";
    const clientSecret = "38742de6997c4423897005a03e47f184";
    const tokenUrl = "https://accounts.spotify.com/api/token";

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
    };

    const body = "grant_type=client_credentials";

    try {
      const response = await axios.post(tokenUrl, body, { headers });
      return response.data.access_token;
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error;
    }
  };

  return (
    <DashboardLayout sectionName="User Management" title="Profile">
      <Box className="view-user-container">
        {loading && (
          <CircularProgress
            size={58}
            style={{ marginLeft: 550, marginTop: 300 }}
          />
        )}
        {error && <Typography>Error: {error.message}</Typography>}
        {!loading && !error && artist && (
          <Box className="view-user-content">
            <Box className="profile-info">
              <Box className="profile-info-inner">
                <Box className="profile-image">
                  <img
                    src={artist.images[1].url}
                    alt="Artist"
                    className="viewProfileImg"
                  />
                </Box>
                <Box className="profile-details">
                  <Typography
                    variant="h1"
                    sx={{
                      fontWeight: 800,
                      fontSize: "6rem",
                      color: "white",
                      marginBottom: "20px",
                    }}
                  >
                    {artist.name}
                  </Typography>
                  <Box className="profile-mindetails">
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "1rem",
                        color: "white",
                      }}
                    >
                      Followers: {formatFollowers(artist.followers.total)}
                    </Typography>

                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "1rem",
                        color: "white",
                        marginBottom: "10px",
                      }}
                    >
                      Popularity: {artist.popularity}
                    </Typography>

                    {revenueEstimates && revenueEstimates.length > 0 && (
                      <Box>
                        <Typography variant="h5">Revenue Estimates:</Typography>
                        {revenueEstimates.map((estimate) => (
                          <Typography key={estimate.year}>
                            Year {estimate.year}: ${estimate.revenue}
                          </Typography>
                        ))}
                      </Box>
                    )}

                    {currentTrack && <Player track={currentTrack} />}
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "1rem",
                    }}
                  >
                    <Link
                      href={artist.external_urls.spotify}
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="none"
                      sx={{ color: "inherit", textDecoration: "none" }} // Custom styles
                    >
                      Spotify <FaExternalLinkAlt />
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Albums */}
            <Box className="albums-list">
              <Typography sx={addEditStyle.addEditLabel}>Albums</Typography>
              <Box className="albums-list-content">
                <Box className="albums-list-inner">
                  {albums.length > 0 ? (
                    albums.map((album) => (
                      <Box
                        key={album.id}
                        className="album-item"
                        onClick={() => setCurrentTrack(album)}
                        sx={addEditStyle.albumTrackItem}
                      >
                        {album.images.length > 0 && (
                          <img
                            src={album.images[0].url}
                            alt={album.name}
                            className="album-image"
                            style={addEditStyle.albumTrackImage}
                          />
                        )}
                        <Typography
                          sx={addEditStyle.addEditInfo}
                          className="album-name"
                        >
                          {album.name}
                        </Typography>
                      </Box>
                    ))
                  ) : (
                    <Typography sx={addEditStyle.addEditInfo}>
                      No albums found
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            {/* Top Tracks */}
            <Box className="top-tracks-list">
              <Typography sx={addEditStyle.addEditLabel}>Top Tracks</Typography>
              <Box className="top-tracks-list-content">
                <Box className="top-tracks-list-inner">
                  {topTracks.length > 0 ? (
                    topTracks.map((track) => (
                      <Box
                        key={track.id}
                        className="track-item"
                        onClick={() => setCurrentTrack(track)}
                        sx={addEditStyle.albumTrackItem}
                      >
                        {track.album.images.length > 0 && (
                          <img
                            src={track.album.images[0].url}
                            alt={track.name}
                            className="track-image"
                            style={addEditStyle.albumTrackImage}
                          />
                        )}
                        <Typography
                          sx={addEditStyle.addEditInfo}
                          className="track-name"
                        >
                          {track.name}
                        </Typography>
                      </Box>
                    ))
                  ) : (
                    <Typography sx={addEditStyle.addEditInfo}>
                      No top tracks found
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {!loading && !error && user && (
          <Box className="view-user-content">
            <Box className="profile-info">
              <Box className="profile-info-inner">
                <Box className="profile-image">
                  <img
                    src={
                      user.images && user.images.length > 1
                        ? user.images[1].url
                        : urlImg
                    }
                    alt={user.name}
                    className="viewProfileImg"
                  />
                </Box>
                <Box className="profile-details">
                  <Typography
                    variant="h1"
                    sx={{
                      fontWeight: 800,
                      fontSize: "6rem",
                      color: "white",
                      marginBottom: "20px",
                    }}
                  >
                    {user.userName}
                  </Typography>
                  <Box className="profile-mindetails">
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "1rem",
                        color: "white",
                      }}
                    >
                      Email: {formatFollowers(user.email)}
                    </Typography>

                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "1rem",
                        color: "white",
                        marginBottom: "10px",
                      }}
                    >
                      Country: {user.country}
                    </Typography>

                    {revenueEstimates && revenueEstimates.length > 0 && (
                      <Box>
                        <Typography variant="h5">Revenue Estimates:</Typography>
                        {revenueEstimates.map((estimate) => (
                          <Typography key={estimate.year}>
                            Year {estimate.year}: ${estimate.revenue}
                          </Typography>
                        ))}
                      </Box>
                    )}

                    {currentTrack && <Player track={currentTrack} />}
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "1rem",
                    }}
                  >
                    {user && (
                      <Link
                        href={`https://open.spotify.com/user/${user.spotifyId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="none"
                        sx={{
                          color: "inherit",
                          textDecoration: "none",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        Spotify{" "}
                        <FaExternalLinkAlt style={{ marginLeft: "4px" }} />
                      </Link>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default ViewUser;
