import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import backBtn from "../../assets/backBtn.svg";
import typographyStyles from "../../theme/Typography";
import LogoutModal from "../../Modal/LogoutModal";
import { useNavigate } from "react-router-dom";

const dashboardLayoutStyle = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  headerContent: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },

  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "1%",
  },

  sectionTitle: {
    ...typographyStyles.h3,
  },

  contentSection: {
    display: "flex",
    // marginTop: '100px',
    // padding: '20px',
    height: "100%",
  },

  sectionNameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  siderbarStyle: {
    // border: '2px solid pink',
    width: "20%",
    minHeight: "100vh",
    // height: "100%",
    boxShadow: "0 10px 10px -10px #D9D9D9, 10px 0 10px -10px #D9D9D9",
  },

  contentStyle: {
    // border: '2px solid yellow',
    width: "80%",
    padding: "16px 2%",
    margin: "12px",
    minHeight: "100vh",
    // height: "100%",
    boxShadow: "0 0 10px 0 #D9D9D9",
  },

  sectionNameStyle: {
    ...typographyStyles.h4,
    // fontWeight: '500',
    // color: 'black',
    textAlign: "end",
  },

  footerSection: {
    left: "0",
    right: "0",
    bottom: "0",
  },
};

const DashboardLayout = ({
  children,
  sectionName,
  showBackButtonSection,
  title,
  rightComponent,
}) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const handleCloseLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  const Navigate = useNavigate();

  return (
    <Box style={dashboardLayoutStyle.mainContainer}>
      <Box style={dashboardLayoutStyle.headerSection}>
        <Header />
      </Box>

      <Box style={dashboardLayoutStyle.contentSection}>
        <Box style={dashboardLayoutStyle.siderbarStyle}>
          <Sidebar onLogoutClick={handleLogoutClick} />
          {isLogoutModalOpen && (
            <LogoutModal onClose={handleCloseLogoutModal} />
          )}
        </Box>
        <Box style={dashboardLayoutStyle.contentStyle}>
          <Box sx={dashboardLayoutStyle.headerContent}>
            <Box style={dashboardLayoutStyle.sectionNameContainer}>
              <Box>
                <Typography style={dashboardLayoutStyle.sectionNameStyle}>
                  {sectionName}
                </Typography>
              </Box>
              <Box>{rightComponent} </Box>
            </Box>

            <Divider />
            
            {showBackButtonSection && (
              <Box sx={dashboardLayoutStyle.titleSection}>
                <img
                  src={backBtn}
                  alt="backBtn"
                  style={{
                    width: "2.813",
                    height: "2.813",
                    cursor: "pointer",
                  }}
                  onClick={() => Navigate(-1)}
                />
                <Typography sx={dashboardLayoutStyle.sectionTitle}>
                  {title}
                </Typography>
              </Box>
            )}
          </Box>
          {children}
        </Box>
      </Box>


      <Box style={dashboardLayoutStyle.footerSection}>
        <Footer />
      </Box>
    </Box>
  );
};
DashboardLayout.defaultProps = {
  showBackButtonSection: true,
};

export default DashboardLayout;
