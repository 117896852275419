import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoMdCopy } from "react-icons/io";
import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import urlimg from "../../../assets/profile_pic.jpeg";
import "../../../scss/main.css";
import { getAPIHandler, postAPIHandler } from "../../../ApiConfig/service";
import { RiEyeFill } from "react-icons/ri";
import { FaReply } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import ReplyPopup from "../../../Modal/ReplyModal";
import toast from "react-hot-toast";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "thumbnail", label: "Image" },
  { id: "user_name", label: "User Name" },
  { id: "email_address", label: "Email Address" },
  { id: "spotify_id", label: "Spotify ID" },
  { id: "feedbackType", label: "Feedback Type" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];

const dropdownOptionsForFeedbackType = ["ALL", "BUG", "SUGGESTION", "OTHER"];

const FeedbackListing = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState("ALL");
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [showReplyPopup, setShowReplyPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [replyText, setReplyText] = useState("");
  const [repliedRows, setRepliedRows] = useState(new Set());

  const navigate = useNavigate();

  const handleViewClick = (SpotifyId, feedbackId, row) => {
    setSelectedRow(row);
    setShowReplyPopup(true);
    navigate("/FeedbackListing/viewfeedback", {
      state: { spotifyId: SpotifyId, feedbackId: feedbackId },
    });
  };

  const handleReplySubmit = async (reply) => {
    if (!selectedRow) return;

    const { email } = selectedRow.userId;
    console.log(`Sending reply "${reply}" to ${email}`);

    try {
      await postAPIHandler({
        endPoint: "replyFeedback",
        dataToSend: { email, reply },
      });
      console.log("Reply sent successfully");

      // Update repliedRows state with the ID of the replied feedback
      setRepliedRows((prevRepliedRows) => {
        const newSet = new Set(prevRepliedRows);
        newSet.add(selectedRow._id);
        return newSet;
      });

      setShowReplyPopup(false);
      setSelectedRow(null);
      setReplyText("");
      fetchFeedbackData(); // Refresh data to ensure UI consistency
    } catch (error) {
      console.error("Error sending reply:", error);
    }
  };

  const handleReplyClick = (SpotifyId, email) => {
    const selectedRow = data.find(
      (row) => row.userId.spotifyId === SpotifyId && row.userId.email === email
    );
    setSelectedRow(selectedRow);
    setShowReplyPopup(true);
  };

  useEffect(() => {
    fetchFeedbackData();
  }, []);

  const fetchFeedbackData = async () => {
    setLoading(true);
    try {
      const response = await getAPIHandler({
        endPoint: "getAllUserFeedback",
      });
      console.log("API Response:", response);
      const { feedbackData, totalPages } = response.data.result;
      setData(feedbackData);
      setFilteredData(feedbackData);

      // Update repliedRows state based on feedback data
      const repliedFeedbackIds = feedbackData
        .filter((item) => item.replySent)
        .map((item) => item._id);
      setRepliedRows(new Set(repliedFeedbackIds));

      setTotalPages(totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCSV = () => {
    const dataWithoutId = filteredData.map(
      ({ _id, userId, ...rest }, index) => ({
        sr_no: index + 1,
        user_name: userId.userName || "-",
        email_address: userId.email || "-",
        spotify_id: userId.spotifyId || "-",
        ...rest,
      })
    );

    const headers = Object.keys(dataWithoutId[0]);

    let csvContent = headers.join(",") + "\n";

    csvContent += dataWithoutId
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const timestamp = format(new Date(), "yyyy-MM-dd_HH:mm");
    saveAs(blob, `application_feedback_list_${timestamp}.csv`);
  };

  const handleFilterChange = (filterCriteria) => {
    let filtered = data;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      const searchText = filterCriteria.searchText.toLowerCase().trim();

      filtered = filtered.filter(
        (category) =>
          category.userId.userName.toLowerCase().includes(searchText) ||
          category.userId.email.toLowerCase().includes(searchText)
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M;
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "ALL"
    ) {
      console.log(filterCriteria.selectedValue);
      setSelectedFeedbackType(filterCriteria.selectedValue);
      filtered = filtered.filter(
        (category) => category.feedbackType === filterCriteria.selectedValue
      );
    } else {
      setSelectedFeedbackType("ALL");
    }

    setFilteredData(filtered);
    setShowNoDataMessage(filtered.length === 0);
    console.log("Filtered Data: ", filtered);
  };

  const handleCopyClick = (spotifyId) => {
    navigator.clipboard.writeText(spotifyId);
    toast.success(`Copied Spotify ID: ${spotifyId} to clipboard`);
  };


  return (
    <DashboardLayout
      sectionName="Application Feedback"
      showBackButtonSection={false}
      rightComponent={
        <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
      }
    >
      <Box className="category-container">
        <Box className="filter-container">
          <FilterBox
            searchbarPlaceholder={"Search by username/email... "}
            dropdownLabel={"Feedback Type"}
            dropdownOptions={dropdownOptionsForFeedbackType}
            defaultSelectedValue={"ALL"}
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className="table-container">
          {showNoDataMessage ? (
            <Box className="no-data-found">
              <h2>No data found !!!</h2>
            </Box>
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData.map((row, index) => ({
                sr_no: index + 1,
                thumbnail: (
                  <img
                    src={
                      row.images && row.userId.images.length > 0
                        ? row.userId.images[0].url
                        : urlimg
                    }
                    alt="thumbnail"
                    width="65"
                    height="35"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = urlimg;
                    }}
                  />
                ),
                email_address: row.userId.email || "-",
                spotify_id: (
                  <Box style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                    {row.userId.spotifyId}
                    <Tooltip title="Copy Spotify ID">
                      <IconButton onClick={() => handleCopyClick(row.userId.spotifyId)}>
                        <IoMdCopy style={{ fontSize: "18px" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ),
                user_name: row.userId.userName || "-",
                feedbackType: row.feedbackType || "-",
                created_date_time:
                  format(new Date(row.createdAt), "yyyy/MM/dd HH:mm", {
                    timeZone: "Asia/Kolkata",
                  }) || "-",
                action: (
                  <Box className="row-button-container">
                    <RiEyeFill
                      onClick={() => {
                        handleViewClick(row.userId.spotifyId, row._id, row);
                      }}
                      style={{
                        color: "rgb(0, 168, 215)",
                        fontSize: "24px",
                      }}
                    />
                    {repliedRows.has(row._id) ? (
                      <FaCheckCircle
                        style={{
                          color: "green",
                          fontSize: "21px",
                        }}
                      />
                    ) : (
                      <FaReply
                        onClick={() => {
                          handleReplyClick(
                            row.userId.spotifyId,
                            row.userId.email
                          );
                        }}
                        style={{
                          color: "rgb(0, 168, 215)",
                          fontSize: "21px",
                        }}
                      />
                    )}
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      <ReplyPopup
        open={showReplyPopup}
        onClose={() => setShowReplyPopup(false)}
        onSubmit={handleReplySubmit}
        selectedRow={selectedRow}
      />
    </DashboardLayout>
  );
};

export default FeedbackListing;
