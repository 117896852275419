import React from "react";
import { Routes, Route } from "react-router-dom";
import LogoutModal from "../Modal/LogoutModal";
import Login from "../views/pages/Auth/Login";
import ForgotPassword from "../views/pages/Auth/ForgotPassword";
import VerifyOTP from "../views/pages/Auth/VerifyOTP";
import ResetPassword from "../views/pages/Auth/ResetPassword";
import Dashboard from "../views/pages/Dashboard/Dashboard";
import MyAccount from "../views/pages/MyAccount/MyAccount";
import EditAccount from "../views/pages/MyAccount/EditAccount";
import ChangePassword from "../views/pages/MyAccount/ChangePassword";
import StaticContentListing from "../views/pages/StaticContentManagement/StaticContentListing";
import PrivacyPolicy from "../views/pages/PrivacyPolicy/PrivacyPolicy";
import EditPrivacyPolicy from "../views/pages/PrivacyPolicy/EditPrivacyPolicy";
// import AboutUs from './views/pages/AboutUs/AboutUs';
// import EditAboutUs from './views/pages/AboutUs/EditAboutUs';
// import TermsAndConditions from './views/pages/T&C/T&C';
// import EditTermsAndConditions from './views/pages/T&C/EditT&C';
import UserListing from "../views/pages/UserManagement/UserListing";
import AddUser from "../views/pages/UserManagement/AddUser";
import EditUser from "../views/pages/UserManagement/EditUser";
import FeedbackListing from "../views/pages/ApplicationFeedback/FeedbackListing";
import ViewFeedback from "../views/pages/ApplicationFeedback/ViewFeedback";
import ViewUser from "../views/pages/UserManagement/ViewUser";
import AuthGaurd from "../components/AuthGaurd";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/test" element={<LogoutModal />} />
      <Route path="/" element={<Login />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/verifyotp" element={<VerifyOTP />} />
      <Route path="/resetpassword" element={<ResetPassword />} />

      <Route element={<AuthGaurd />}>
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/Userlisting" element={<UserListing />} />
        <Route path="/Userlisting/viewuser/:id" element={<ViewUser />} />
        <Route path="/Userlisting/adduser" element={<AddUser />} />
        <Route path="/Userlisting/edituser" element={<EditUser />} />

        <Route path="/FeedbackListing" element={<FeedbackListing />} />
        <Route
          path="/FeedbackListing/viewfeedback"
          element={<ViewFeedback />}
        />

        <Route path="/myaccount" element={<MyAccount />} />
        <Route path="/myaccount/editaccount" element={<EditAccount />} />
        <Route path="/myaccount/changepassword" element={<ChangePassword />} />

        <Route path="/staticcontent" element={<StaticContentListing />} />

        <Route path="/staticcontent/view" element={<PrivacyPolicy />} />
        <Route path="/staticcontent/edit" element={<EditPrivacyPolicy />} />

        {/* <Route path='/staticcontent/aboutus' element={<AboutUs />}/>
        <Route path='/staticcontent/editaboutus' element={<EditAboutUs />}/>

        <Route path='/staticcontent/termsandconditions' element={<TermsAndConditions />}/>
        <Route path='/staticcontent/edittermsandconditions' element={<EditTermsAndConditions />}/> */}
      </Route>
    </Routes>
  );
};
