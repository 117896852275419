import { Box, styled } from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const MainContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
});

const ContentSection = styled(Box)(({ theme }) => ({
    flex: '1 0 auto',
    marginLeft: '25%',
    marginRight: '25%',
    paddingTop: '5%',
    [theme.breakpoints.down('md')]: {
        marginLeft: '20%',
        marginRight: '20%',
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '10%',
        marginRight: '10%',
    },
}));

const FooterSection = styled(Box)({
    flexShrink: 0,
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
});

const LoginLayout = ({ children }) => {
    return (
        <MainContainer>
            <Box><Header /></Box>
            <ContentSection>
                {children}
            </ContentSection>
            <FooterSection>
                <Footer />
            </FooterSection>
        </MainContainer>
    );
};

export default LoginLayout;
