import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Searchbar from "./Searchbar";
import typographyStyles from "../theme/Typography";
import ResponsiveDatePickers from "./DatePicker";
import Dropdown from "./Dropdown";
import Button from './Button';
import { WidthFull } from "@mui/icons-material";


const filterBoxStyle = { 
    filterBoxcontent: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems:'end',
        // border: '1px solid rgb(0, 168, 215)',
        // padding: '1%',
    },

    filterBoxItem: {
        display: 'flex',
        flexDirection: 'column',
      
        
    },

    // filterBoxItemButton: {
    //     display: 'flex',
    //     alignItems: 'center !important',
    //     justifyContent: 'center'
    // },
    
    filterlabel: {      
      ...typographyStyles.h6, // Apply typography styles here
      color: 'black',
    },
  };



  const FilterBox = ({ searchbarPlaceholder, dropdownOptions, dropdownLabel, onFilterChange,  defaultSelectedValue }) => {

    const [searchText, setSearchText] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);


    const handleDropdownChange = (value) => {
        setSelectedValue(value);
        onFilterChange({ searchText, fromDate, toDate, selectedValue : value })
    };

    const handleClear = () => {
        setSearchText('');
        setFromDate(null);
        setToDate(null);
        setSelectedValue(defaultSelectedValue);
        onFilterChange({ searchText: "", fromDate: null, toDate : null, selectedValue : defaultSelectedValue })
    };

    

    return (
        <Box style={filterBoxStyle.filterBoxcontent}>
            <Box style={filterBoxStyle.filterBoxItem}>
                
                <Searchbar placeholder={searchbarPlaceholder} value={searchText} onChange={(e) => {setSearchText(e.target.value); onFilterChange({ searchText: e.target.value, fromDate, toDate , selectedValue })}} />
            </Box>
            <Box style={filterBoxStyle.filterBoxItem}>
                
                <ResponsiveDatePickers value={fromDate} label={"From Date"} onChange={(date) => {setFromDate(date); onFilterChange({ searchText , fromDate, toDate , selectedValue})}}/>
            </Box>
            <Box style={filterBoxStyle.filterBoxItem}>
             
                <ResponsiveDatePickers value={toDate} label={"To Date"} onChange={(date) => {setToDate(date); onFilterChange({ searchText , fromDate, toDate , selectedValue })}}/>
            </Box>
            <Box style={filterBoxStyle.filterBoxItem}>
                
                <Dropdown options={dropdownOptions} value={selectedValue} onChange={handleDropdownChange}  />
            </Box>
            <Box style={filterBoxStyle.filterBoxItemButton} >
                <Button text="Reset Filter" onClick={handleClear} />
            </Box>
        </Box>
    );
};

export default FilterBox;