import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import LoginLayout from "../../../layouts/LoginLayout/LoginLayout";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../../../schema";
import "../../../scss/main.css";
import InputField from "../../../components/InputField";
import { postAPIHandlerWithoutToken } from "../../../../src/ApiConfig/service/index";
import { toast, ToastContainer } from "react-toastify";


const authStyle = {
  authHeading: {
    textAlign: "center",
    ...typographyStyles.h3,
  },

  authSubHeading: {
    ...typographyStyles.h6,
    color: "#0009",
    textAlign: "center",
  },

  authLabel: {
    ...typographyStyles.h6,
  },

  forBack: {
    ...typographyStyles.h6,
    color: "#3996d2",
    textAlign: "center",
    cursor: "pointer",
  },
  authButton: {
    width: "50%",
    margin: "0 auto",
  }
};

const ResetPassword = () => {
  const location = useLocation();
  const email = location.state?.email;
  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const Navigate = useNavigate();

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: resetPasswordSchema,
      onSubmit: async (values) => {
        try {
          setIsUpdating(true);
          const response = await postAPIHandlerWithoutToken({
            endPoint: "resetPassword",
            dataToSend: {
              email: email.toLocaleLowerCase(),
              newPassword: values.newPassword,
            },
          });

          if (response.data.responseCode === 200) {
            toast.success("Password reset successfully.");
            Navigate("/");
          } else {
            toast.error(response.data.responseMessage);
          }
          setIsUpdating(false);
        } catch (error) {
          toast.error("Failed to reset password. Please try again later.");
          console.error("Error:", error);
          setIsUpdating(false);
        }
      },
    });

  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <LoginLayout style={{ border: "2px solid red" }}>
      <Box className="authBox">
        <form className="authContainer" onSubmit={handleSubmit}>
          <Box className="authHeadingBox">
            <Typography style={authStyle.authHeading}>
              Reset Password
            </Typography>
            <Typography style={authStyle.authSubHeading}>
              Enter your new password.
            </Typography>
          </Box>
          <Box className="authContent">
            <Box className="authFields">
              <Typography variant="h6" style={authStyle.authLabel}>
                New Password
              </Typography>
              <InputField
                className="authTextField"
                type="password"
                name="newPassword"
                value={values.newPassword}
                maxLength={20}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter the new password"
              />
              {errors.newPassword && touched.newPassword ? (
                <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                  {errors.newPassword}
                </p>
              ) : null}
            </Box>

            <Box className="authFields">
              <Typography variant="h6" style={authStyle.authLabel}>
                confirm Password
              </Typography>
              <InputField
                className="authTextField"
                type="password"
                name="confirmPassword"
                value={values.confirmPassword}
                maxLength={20}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter the confirm password"
              />
              {errors.confirmPassword && touched.confirmPassword ? (
                <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                  {errors.confirmPassword}
                </p>
              ) : null}
            </Box>
          </Box>
          <Box className="authFields" style={authStyle.authButton}>
            <BasicButtons
              text="Submit"
              name="submit"
              type="submit"
              disabled={isUpdating}
            />
          </Box>
          <Typography
            style={authStyle.forBack}
            onClick={() => {
              Navigate("/");
            }}
          >
            Back to login
          </Typography>
        </form>
      </Box>
      <ToastContainer />
    </LoginLayout>
  );
};

export default ResetPassword;
