import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import LoginLayout from "../../../layouts/LoginLayout/LoginLayout";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "../../../schema";
import "../../../scss/main.css";
import InputField from "../../../components/InputField";
import { toast, ToastContainer } from "react-toastify";
import { postAPIHandlerWithoutToken } from "../../../../src/ApiConfig/service/index";

const authStyle = {
  authHeading: {
    textAlign: "center",
    ...typographyStyles.h3,
  },

  authSubHeading: {
    ...typographyStyles.h6,
    color: "#0009",
    textAlign: "center",
  },

  authLabel: {
    ...typographyStyles.h6,
  },

  forBack: {
    ...typographyStyles.h6,
    color: "#3996d2",
    textAlign: "center",
    cursor: "pointer",
  },
  authButton: {
    width: "50%",
    margin: "0 auto",
  }
};

const ForgotPassword = () => {
  const initialValues = {
    email: "",
  };

  const navigate = useNavigate();

  const { values, errors, handleBlur, handleChange, touched, handleSubmit, isValid } =
    useFormik({
      initialValues: initialValues,
      validationSchema: forgotPasswordSchema,
      onSubmit: (values) => {
        handleFormSubmit(values);
      },
    });

  const [isUpdating, setIsUpdating] = useState(false);
  const handleFormSubmit = async (values) => {
    try {
      setIsUpdating(true);
      const response = await postAPIHandlerWithoutToken({
        endPoint: "forgotPassword",
        dataToSend: {
          email: values.email.toLocaleLowerCase(),
        },
      });
      if (response && response.data.responseCode === 200) {
        toast.success("OTP sent successfully! Please check your email.");

        const { otp } = response.data.result;
        sessionStorage.removeItem("otpTimer");

        navigate("/VerifyOTP", {
          state: {
            email: values.email.toLocaleLowerCase(),
            otp
          }, replace: true
        });
      } else {
        
        if (response && response.data.responseCode === 404) {
          toast.error("Please enter a registered email.");
        }
      }
      setIsUpdating(false);
    } catch (error) {
      toast.error("Failed to send OTP. Please try again later.");
      setIsUpdating(false);
    }
  };

  return (
    <LoginLayout>
      <Box className="authBox">
        <form className="authContainer" onSubmit={handleSubmit}>
          <Box className="authHeadingBox">
            <Typography style={authStyle.authHeading}>
              Forgot Your Password?
            </Typography>
            <Typography style={authStyle.authSubHeading}>
              Please enter your registered email here and we will send OTP to
              reset your password.
            </Typography>
          </Box>
          <Box className="authContent">
            <Box className="authFields">
              <Typography variant="h6" style={authStyle.authLabel}>
                Email
              </Typography>
              <InputField
                className="authTextField"
                name="email"
                type="text"
                value={values.email}
                maxLength={64}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter the email address"
              />
              {errors.email && touched.email ? (
                <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                  {errors.email}
                </p>
              ) : null}
            </Box>
          </Box>
          <Box className="authFields" style={authStyle.authButton}>
            <BasicButtons
              text="Send"
              name="send"
              type="submit"
              disabled={isUpdating || !isValid}
            />
          </Box>
          <Typography
            style={authStyle.forBack}
            onClick={() => {
              navigate("/");
            }}
          >
            Back to login
          </Typography>
        </form>
      </Box>
      <ToastContainer />
    </LoginLayout>
  );
};

export default ForgotPassword;
